import {
  Box, Typography, Container, Divider, SvgIcon, Button,
  Tooltip,
  IconButton,
  TextField,
  CircularProgress
} from "@mui/material";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import blogs_1 from "../assets/img/blogs1.png";
import { IGetBlogsDet, IGetBlogId } from "../redux/api/types";
import { useGetBlogDetQuery, useGetBlogidQuery } from "../redux/api/promptApi";
import { JSXElementConstructor, Key, ReactElement, ReactNode, useEffect, useState } from "react";
import { useAppDispatch } from "../redux/store";
import BlogIndexPage from "../pages/BlogIndexPage";
import { setModule } from "../redux/features/genieSlice";
import img54 from "../assets/newimg/logo.png";
import { useGetgeneratedBlogListingsQuery, useGetrandomBlogListingsQuery, useGetTagKeywordsMutation, useGetBlogsByCampaignQuery } from '../redux/api/promptApi';
import { useBlogsCountQuery } from "../redux/api/promptApi";
import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { isMobile } from "react-device-detect";
import { usePlanDetailsMutation } from "../redux/api/productApi";
import { ReactComponent as TwitterIcon } from '../assets/traffic_monster/icon_twitter.svg';
import { ReactComponent as PinterestIcon } from '../assets/traffic_monster/pinterest_ico.svg';
import { ReactComponent as FacebookIcon } from '../assets/traffic_monster/fb_ico.svg';
import { ReactComponent as LinkedinIcon } from '../assets/traffic_monster/linkedIn_ico.svg';
import { Helmet } from 'react-helmet';
import { Facebook, Twitter, LinkedIn, Pinterest } from "@mui/icons-material";
import equip_your_kids from '../assets/traffic_monster/equip_your_kids.png';
import logo_white from '../assets/logo_white.png';
import { CSSProperties } from 'react';
import { IGetBlogsAdmin } from "../redux/api/types";


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>

const ADiveintoBlogs1 = () => {
  const params = useParams();
  const params1 = new URLSearchParams(window.location.search);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const allParams = location.search.split('?');
  const firstCampIdParam = allParams.find((param: any) => param.includes('campId='));

  const campId = firstCampIdParam ? firstCampIdParam.split('=')[1] : '';
  // const campId = searchParams.get('campId') || '';
  console.log('pr=>', campId);

  const [blogDetails, setBlogDetails] = useState<IGetBlogId[]>([]);
  const [productId, setProductId] = useState<any>();
  const [cta, setCta] = useState("")
  const [ctalabel, setCtaLabel] = useState("")
  const [totalPages, setTotalPages] = useState(0);
  const [randomInteger, setRandomInteger] = useState(0);
  const blgurl = params.url || '';
  const blogid = params.blog_id || '';
  const [blog_Id, setBlogId] = useState<string | null | undefined>(params.blog_id);
  const { data: getblogdetails } = useGetBlogidQuery({ blog_id: blogid || blgurl || '', campId: campId || '' });
  const [blogs, setBlogs] = useState<IGetBlogsAdmin[]>([]);
  const [planDetails, setPlanDetails] = useState<any>();
  const [getuserPlanDetails] = usePlanDetailsMutation();
  const [getTagKeywordsmutation] = useGetTagKeywordsMutation();
  const user_email = localStorage.getItem('user_email') || '';
  const [clName, setCLName] = useState<any>();
  const [clEmail, setCLEmail] = useState<any>();
  const [clPhone, setCLPhone] = useState<any>();;
  const [clHelpComment, setCLHelpComment] = useState<any>();
  const [formErrors, setFormErrors] = useState<any>();
  const [newsLetterEmail, setNewsLetterEmail] = useState<any>();
  const [tags, setTags] = useState<string[]>([]);

  const promptid = params1.get('promptid') ?? "";


  // Check if getblogdetails and getblogdetails.data are defined and have at least one element
  const userId = getblogdetails?.data && getblogdetails.data.length > 0 && getblogdetails.data[0]['userid'] !== "undefined"
    ? getblogdetails.data[0]['userid'] || ""
    : "";

  // Always call the hook, passing the userId (which might be empty)
  const getBlogsCount = useBlogsCountQuery({
    promptid: promptid,
    userid: userId,  // This will be an empty string if the condition is not met
    cat: "",
    query: "",
    domain: "",
  });


  useEffect(() => {
    if (getBlogsCount?.data) {
      setTotalPages(getBlogsCount?.data);
    }
  }, [getBlogsCount?.data]);



  useEffect(() => {
    // Calculate random integer when totalPages is available
    if (totalPages !== null) {
      const min = 1;
      const max = totalPages;
      const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;
      setRandomInteger(randomInteger);
    }
  }, [totalPages]);

  /* News portion Blogs */

  // const getBlogs = useGetgeneratedBlogListingsQuery({ promptid: promptid, userid: getblogdetails?.data[0]['userid'] || "", limitval: 3, skipval: randomInteger });
  const getBlogs = useGetBlogsByCampaignQuery({ campaignid: campId || '', /* userid: getblogdetails?.data[0]['userid'] || "", */ limitval: 3, skipval: randomInteger });


  /* useEffect(() => {
    if (getBlogs.data) {
      setBlogs([...getBlogs.data]);
    }
  }, [getBlogs]); */
  useEffect(() => {
    if (getBlogs.data) {
      setBlogs((prevBlogs: any) => [...prevBlogs, ...(getBlogs.data || [])].slice(0, 3));
    }
  }, [getBlogs]);
  /*  */
  console.log(getBlogs);
  useEffect(() => {

    if (getblogdetails && getblogdetails.data) {


      if (getblogdetails.data[0]['statichtml'] && getblogdetails.data[0]['mode'] == 'Live' && (campId == "" || campId == "undefined")) { //if static html generated redirec to that page

        window.location.href = getblogdetails.data[0]['canonical_url'];
        return;
      }
      console.log('getblogdetails.data----');
      if (getblogdetails.data[0]['cta_label'])
        setCtaLabel(getblogdetails.data[0]['cta_label'])
      if (getblogdetails.data[0]['cta_url'])
        setCta(getblogdetails.data[0]['cta_url'])
      setBlogDetails([...getblogdetails.data])
    }
  }, [getblogdetails])
  console.log('blog det=>', blogDetails);
  /*  */

  /*  */


  const handleGetBlogId = () => {
    if (getblogdetails?.data && getblogdetails?.data.length > 0) {
      const blogIdFromGetBlogDetails = getblogdetails?.data[0]._id;
      setBlogId(blogIdFromGetBlogDetails);
    }
  };

  useEffect(() => {
    handleGetBlogId();
  }, [getblogdetails]);

  const navigation = blogDetails.map((blog, index) => {
  })

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const handleLearnMore = () => {
    /* const navigation = blogDetails.map((blog, index) => {
      if (blog.cat == 'cat1' || blog.cat == 'SMART CONTRACTS') {
        debugger
        dispatch(setModule("SMART CONTRACTS"));
        navigate('/');
      }
      else {
        dispatch(setModule("Any Code"));
        navigate('/Anycode_landing');
      }
    }) */
    const navigation = blogDetails.map((blog, index) => {
      if (blog.cat == 'cat1' || blog.cat == 'SMART CONTRACTS') {
        //debugger
        dispatch(setModule("SMART CONTRACTS"));
        navigate('/');
      }
      else {
        window.open(cta, "_blank")
      }
      // else if (blog.cat == 'CONSULTING') {
      //   window.open("http://consulting.code-genie.ai","_blank")
      // }
      // else if (blog.cat == 'AI COURSE') {
      //   window.open("http://ai-course.code-genie.ai","_blank")
      // }
      // else {
      //   dispatch(setModule("Any Code"));
      //   //window.open(learnMoreUrl,"_blank");
      //   window.open("https://codegenieai.myclickfunnels.com/optin","_blank")
      // }
    })
  }
  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();
  const [subscriptionLoader, setSubscriptionLoader] = useState<boolean>(false);
  const [leadsLoader, setleadsLoader] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState("");

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .then((response) => {
        if ('data' in response) {
          const status = response.data.status;
          console.log(status);
          if (status === 'success') {
            toast.success(response.data.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
        } else {
          // Error case
          console.error('Error sending query:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error sending query:', error);

      });
  }

  const prevImg = localStorage.getItem('prevImg');

  const fetchPlanDatas = async () => {
    try {
      const response = await getuserPlanDetails({ email: user_email, campaignid: campId || '' /* '667281c50b6d6fa0eb09bd5a' *//* blogDetails[0].product_id || ''  */ });

      if ('data' in response) {
        setPlanDetails(response.data.data);
        console.log('plan details=>', response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPlanDatas();
  }, []);

  /* const fetchKeywordDatas = async () => {
    try {
      const response = await getTagKeywordsmutation({ campaignid: blogDetails[0].product_id || '' });

      if ('data' in response) {
        setTags(response.data.data);
        console.log('tag dets=>', response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  } */

  useEffect(() => {
    const fetchKeywordDatas = async () => {
      try {
        const response = await getTagKeywordsmutation({ campaignid: campId || '', blogid: blog_Id || '' });

        if ('data' in response) {
          // Ensure response.data.data is treated as an array of strings
          const data: string[] = response.data.data;
          // Split each item in response.data.data into keyword and id
          const parsedTags = data.map(item => {
            const [keyword, id] = item.split('::');
            return { keyword, id };
          });

          setTags(response.data.data);
          console.log('tag dets=>', response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchKeywordDatas();
  }, []);

  /* const handleShare = (url: any) => {
    window.open(url, '_blank');
  };

  const shareOnTwitter = () => {
    handleShare(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`);
  };

  const shareOnPinterest = () => {
    handleShare(`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}`);
  };

  const shareOnFacebook = () => {
    handleShare(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`);
  }; */

  // const shareOnLinkedin = () => {
  // handleShare(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`);
  /* {blogDetails && blogDetails.map((blog: any, index: any)=>{
  handleShare(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(blog.url)}`);
})} */
  // };

  /* const shareOnLinkedin = () => {
    if (blogDetails && blogDetails.length > 0) {
    blogDetails.forEach((blog: any, index: any) => {
    const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent('Discover-the-Heart-of-Functional-Fitness-at-RISE-Athlete-Calgary.htm')}&title=${encodeURIComponent(blog.blog_title)}&summary=${encodeURIComponent(blog.blog_summary_draft)}&source=${encodeURIComponent(window.location.hostname)}`;
    handleShare(shareUrl);
    });
    } else {
    console.log('No blog details available');
    }
  }; */
  const url = window.location.origin + '/blog/' + blogDetails[0]?.url;

  const handleShare = (platform: any) => {
    let shareUrl = '';
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}`;
        break;
      case 'pinterest':
        const image = 'https://www.podcastmonster.ai' + blogDetails[0]?.blog_image_path;
        shareUrl = `https://pinterest.com/pin/create/button/?media=${image}`;
        break;
      default:
        break;
    }
    window.open(shareUrl, '_blank');
  };

  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleContactUsClick = () => {
    const errors = {
      name: !clName,
      email: !clEmail,
      phone: !clPhone,
      helpComment: !clHelpComment
    }
    setFormErrors(errors);
    setleadsLoader(true);
    const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
    fetch(apiUrl + '/api/prompts/sendleads', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ clientemail: user_email, clientname: '', name: clName, phone: clPhone, email: clEmail, comment: clHelpComment }),
    })
      .then((response) => response.json())
      .then((data) => {
        setleadsLoader(false);
        if (data.status === 'success') {
          toast.success('Thanks for contacting us.');
        } else {
          alert('Subscription failed. Please try again.');
        }
      })
      .catch((error) => {
        setleadsLoader(false);
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      });
  }

  const handleSubscribeClick = () => {
    const errors = {
      subscribeEmail: !newsLetterEmail
    }
    setFormErrors(errors);
    setSubscriptionLoader(true);
    const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
    fetch(apiUrl + '/api/prompts/sendnewslettersubscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ clientemail: user_email, clientname: '', email: newsLetterEmail, }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSubscriptionLoader(false);
        if (data.status === 'success') {
          toast.success('Subscription successful!');
        } else {
          alert('Subscription failed. Please try again.');
        }
      })
      .catch((error) => {
        setSubscriptionLoader(false);
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      });
  }

  const videoTag = blogDetails?.[0]?.videotag || planDetails?.videotag;

  const getEmbeddedURL = (url: any) => {
    const youtubeMatch = url?.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );

    if (youtubeMatch) {
      return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    }

    return url;
  };

  const embeddedURL = getEmbeddedURL(videoTag);

  console.log(embeddedURL);

  const containerStyle: CSSProperties = {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    height: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const iframeStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  const handleMLTtitleimageClick = (blog: any) => {
    if (blog.statichtml === true) {
      window.open(`/blog/${blog.url}.htm`, '_blank');
    } else {
      window.open(`/PreviewBlogs1/${blog._id}?campId=${campId}`, '_blank');
    }
  }

  useEffect(() => {
    setTimestamp(`?t=${Date.now()}`);
  }, []);
  return (
    <>
      <>
        <Helmet>
          <title>{(blogDetails[0]?.blog_title || blogDetails[0]?.blog_title_draft)}</title>
          <meta name="title" content={blogDetails[0]?.blog_title || ''} />
          <meta name="description" content={blogDetails[0]?.blog_summary_draft || ''} />
          <meta property="og:title" content={blogDetails[0]?.blog_title || ''} />
          <meta property="og:description" content={blogDetails[0]?.blog_summary_draft || ''} />
          <meta property="og:image" content={blogDetails[0]?.blog_image_path_draft ? blogDetails[0]?.blog_image_path_draft : blogDetails[0]?.blog_image_path} />
          <meta property="og:url" content={window.location.href} />
          {/* <meta name="keywords" content={`${(keyWord || blogDetails[0]?.blog_title)}`} /> */}
        </Helmet>
      </>
      <Container /* maxWidth="lg" */ style={{ backgroundColor: 'white', paddingTop: '34px', maxWidth: '1283px', margin: '10px auto' }}>
        <Box >

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'auto'/* '100%' */, maxHeight: /* '485px' */'690px', overflow: 'hidden' }}>
            {blogDetails.map((blog, index) => {
              const blogImagePath = `${blog.blog_image_path_draft}${timestamp}`;

              return (
                <>
                  <img className="img-fluid auto-resize card-img-top" alt={"Code Genie:" + (blog.blog_title_draft)} style={{ /* maxWidth: '1152px', */ maxHeight: '500px', objectFit: 'cover', objectPosition: 'center top'/* , width: '96%' */, width: 'auto' }} src={/* blog.blog_image_path ? blog.blog_image_path : (blog.mode === 'draft' ? */ blogImagePath /* : blog.blog_image_path) */} title={"Code Genie:" + (blog.blog_title_draft)} />

                </>
              )
            })}
          </div>



          <Typography
            variant="h3"
            color="black"
            fontWeight={600}
            textAlign="center"
            mb={10}


          >
            {blogDetails.map((blog, index) => (
              <>
                {blog.blog_title_draft ? blog.blog_title_draft.replace(/\"/g, '').replace(/\*\*/g, '') : blog.blog_title.replace(/\"/g, '').replace(/\*\*/g, '')}

              </>
            ))}
          </Typography>


          <div style={{ marginLeft: '35px' }} className="author-details d-inline-block">
            <div style={{ float: 'left' }}>
              {blogDetails.map((blog, index) => (
                <>

                  {blog?.author_image && (
                    <img className="ellipse-18" alt="Ellipse" style={{ height: 40, width: 30, alignItems: "flex-end" }} src={blog.author_image} title="Author Photo" />
                  )
                  }
                  &nbsp; <a href={blog.author_url ? blog.author_url : blog.cta_url} target='_blank'>{blog.author}</a>, Published on: {blog.publish_date}
                </>
              ))}

            </div>
          </div>
          <br /><br />
          <div>
            {videoTag && (
              <div>
                <div className="row" style={{ height: embeddedURL?.includes('youtube.com/embed') ? '400px' : '300px' }}>
                  <div
                    className="col-md-12"
                    style={{
                      display: embeddedURL?.includes('youtube.com/embed') ? 'flex' : 'block',
                      justifyContent: embeddedURL?.includes('youtube.com/embed') ? 'center' : 'initial',
                      height: '100%'
                    }}
                  >
                    {embeddedURL?.includes('youtube.com/embed') ? (
                      <iframe
                        width="60%"
                        height="100%"
                        src={embeddedURL}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    ) : (
                      <iframe
                        width="320"
                        height="440"
                        src={blogDetails?.[0]?.videotag || planDetails?.videotag}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    )}
                  </div>
                </div>
                <br />
              </div>
            )}
          </div>


          <Typography
            variant="h6"
            color="black"
            fontWeight={300}
            textAlign="justify"
            mb={videoTag ? 10 : 0}

          >

            {blogDetails.map((blog, index) => (
              <div key={index}>
                {/* {blog.blog_det_draft ? ( */}
                <div dangerouslySetInnerHTML={{ __html: blog.blog_det_draft.replace("<h2>" + blog.blog_title_draft + "</h2>", "") }} />
                {/* ) : (
                  <div dangerouslySetInnerHTML={{ __html: blog.blog_det.replace("<h2>"+blog.blog_title+"</h2>", "") }} />
                )} */}
              </div>
            ))}

            {(planDetails?.twitternbutton === 1 || planDetails?.pintrestBtn === 1 || planDetails?.fbbutton === 1 || planDetails?.linkedinbutton === 1) && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Share:</Typography>
                {planDetails?.twitternbutton === 1 && (
                  <Tooltip title="Share on Twitter">
                    <TwitterIcon onClick={() => handleShare('twitter')} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
                {planDetails?.pintrestbutton === 1 && (
                  <Tooltip title="Share on Pinterest">
                    <PinterestIcon onClick={() => handleShare('pinterest')} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
                {planDetails?.fbbutton === 1 && (
                  <Tooltip title="Share on Facebook">
                    <FacebookIcon onClick={() => handleShare('facebook')} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
                {planDetails?.linkedinbutton === 1 && (
                  <Tooltip title="Share on LinkedIn">
                    <LinkedinIcon onClick={() => handleShare('linkedin')} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </Box>
            )}

            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '10px', marginTop: '1%' }}>
              <Typography sx={{ marginTop: '0px' }}>Tags:</Typography>
              <Typography sx={{ color: '#1570EF' }}>
                {tags.map((tag: string) => {
                  const [keyword, id] = tag.split('::');

                  if (id && id.trim() !== '') {
                    return (
                      <span key={tag}>
                        <span>
                          <a href={`/PreviewBlogs1/${id}?campId=${campId}`} target="_blank">{keyword}</a>
                        </span>,&nbsp;
                      </span>
                    );
                  } else {
                    return (
                      <span key={tag}>
                        <span>{keyword}</span>,&nbsp;
                      </span>
                    );
                  }

                })}
              </Typography>

            </Box>

            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
              <Button variant="contained" sx={{ background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)", color: "white", float: 'left', marginTop: '2%', padding: '10px 30px' }}
                onClick={handleLearnMore}
              >
                {planDetails?.cta_lable ? planDetails.cta_lable : ctalabel}<KeyboardArrowRight />
              </Button>
              {/* <br /><br /> */}
            </div>
            <br /><br />
            {/* {videoTag ?
              <div style={containerStyle}>
                {embeddedURL?.includes('youtube.com/embed') ? (
                  <iframe
                    style={iframeStyle}
                    width="320"
                    height="240"
                    src={embeddedURL}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : <iframe
                  width="320"
                  height="440"
                  src={blogDetails?.[0]?.videotag || planDetails?.videotag}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>}
              </div>
              : ''} */}
            {/*  {videoTag ?
              <div style={containerStyle}>
                {embeddedURL?.includes('youtube.com/embed') ? (
                  <iframe
                    style={iframeStyle}
                    width="320"
                    height="240"
                    src={embeddedURL}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : <iframe
                  width="320"
                  height="440"
                  src={blogDetails?.[0]?.videotag || planDetails?.videotag}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>}
              </div> : ''} */}

            {/* <div style={containerStyle}> */}

            {/* </div> */}

            {planDetails?.capturleads === 1 ?
              <div>
                <Divider sx={{ color: '#CBCBCB' }} />
              </div>
              : <></>}

            {planDetails?.capturleads === 1 ?
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: isMobile ? '70vh' : '100vh' }}>

                <Box
                  sx={{
                    border: '1px solid #CBCBCB',
                    padding: '3%',
                    borderRadius: '6px',
                    width: isMobile ? '95%' : '50%',
                    height: isMobile ? '450px' : '500px',
                    // marginTop: '2%'
                    marginBottom: isMobile ? '' : '14%'
                  }}>
                  <div>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bolder', color: '#191D23' }}>Contact Us</Typography>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'lighter' }}>Reach out to us and let us know how we can help you.</Typography>
                  </div>
                  <div style={{ marginTop: '7%' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Name<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField
                      placeholder="Enter your name"
                      sx={{
                        backgroundColor: '#fff',
                        width: '100%',
                        height: '40px',
                        '.MuiInputBase-root': {
                          height: '100%',
                          border: 'none',
                          borderRadius: '5px',
                          '&:hover fieldset': {
                            borderColor: '#CBCBCB',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#CBCBCB',
                            boxShadow: 'none',
                          },
                        },
                        border: formErrors?.name ? '1px solid red' : '1px solid #CBCBCB',
                        borderRadius: '5px',
                        '&:hover': {
                          border: 'none'
                        }
                        // marginLeft: '10px'
                      }} name="name" value={clName} onChange={(event) => setCLName(event?.target.value)} />
                  </div>
                  <div style={{ marginTop: '2%' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Email<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField
                      placeholder="Enter your email"
                      sx={{
                        backgroundColor: '#fff',
                        width: '100%',
                        height: '40px',
                        '.MuiInputBase-root': {
                          height: '100%',
                          border: 'none',
                          borderRadius: '5px',
                          '&:hover fieldset': {
                            borderColor: '#CBCBCB',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#CBCBCB',
                            boxShadow: 'none',
                          },
                        },
                        border: formErrors?.email ? '1px solid red' : '1px solid #CBCBCB',
                        borderRadius: '5px',
                        '&:hover': {
                          border: 'none'
                        }
                        // marginLeft: '10px'
                      }} name="email" value={clEmail} onChange={(event) => setCLEmail(event?.target.value)} />
                    {clEmail ? <span style={{ color: 'red' }}>{!validateEmail(clEmail) ? 'Invalid Email' : ''}</span> : ''}
                  </div>
                  <div style={{ marginTop: '2%' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Phone<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField
                      placeholder="Enter your phone number"
                      sx={{
                        backgroundColor: '#fff',
                        width: '100%',
                        height: '40px',
                        '.MuiInputBase-root': {
                          height: '100%',
                          border: 'none',
                          borderRadius: '5px',
                          '&:hover fieldset': {
                            borderColor: '#CBCBCB',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#CBCBCB',
                            boxShadow: 'none',
                          },
                        },
                        border: formErrors?.phone ? '1px solid red' : '1px solid #CBCBCB',
                        borderRadius: '5px',
                        '&:hover': {
                          border: 'none'
                        }
                        // marginLeft: '10px'
                      }} name="phone" value={clPhone} onChange={(event) => setCLPhone(event?.target.value)} />
                  </div>
                  <div style={{ marginTop: '2%' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>How can we help?<span style={{ color: 'red' }}>*</span></Typography>
                    <TextField
                      placeholder="Let us know how we can help you"
                      sx={{
                        backgroundColor: '#fff',
                        width: '100%',
                        height: '40px',
                        '.MuiInputBase-root': {
                          height: '100%',
                          border: 'none',
                          borderRadius: '5px',
                          '&:hover fieldset': {
                            borderColor: '#CBCBCB',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#CBCBCB',
                            boxShadow: 'none',
                          },
                        },
                        border: formErrors?.helpComment ? '1px solid red' : '1px solid #CBCBCB',
                        borderRadius: '5px',
                        '&:hover': {
                          border: 'none'
                        }
                        // marginLeft: '10px'
                      }} name="helpComment" value={clHelpComment} onChange={(event) => setCLHelpComment(event?.target.value)} />
                  </div>
                  <div style={{ marginTop: '5%' }}>
                    {/* <Button variant="contained" sx={{width: '100%'}}>Subscribe</Button> */}
                    <Button variant="contained" sx={{
                      width: '100%', color: '#fff', /* '&:hover': {
                      background: '#EAECF0',
                    } */
                    }} onClick={handleContactUsClick}>{leadsLoader ? <CircularProgress size={22} style={{ color: '#fff' }} /> : 'Contact Us'}</Button>
                  </div>

                </Box>
              </div>
              : <></>}

          </Typography>
        </Box>



      </Container>

      {/* More like this section */}
      <div className="container my-sm-5 my-3">
        <div className="">
          <h4 className="main_heading my-3 text-md-start text-center" style={{ color: '#fff' }}>More like this...</h4>
        </div>
        <div className="row">
          {blogs && blogs.slice(0, 3).map((blog: any, index: any) => (
            <div className="col-md-4 mb-md-0 mb-3">
              <div>
                <div
                  className="card p-3 text-white"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #475569",
                    marginBottom: "1.5rem",
                  }}
                >
                  <div style={{ maxHeight: 300, minHeight: isMobile ? '' : 250/* 300 */, overflow: 'Hidden' }}><img className="img-fluid auto-resize card-img-top" style={{ maxWidth: isMobile ? '330px' : '', cursor: 'pointer' }} src={blog.blog_image_path_draft ? blog.blog_image_path_draft : blog.blog_image_path} alt="" onClick={() => handleMLTtitleimageClick(blog)} /></div>
                  <div className="card-body">
                    <h4 className="card-title" style={{ cursor: 'pointer' }} onClick={() => handleMLTtitleimageClick(blog)}>
                      {blog.blog_title_draft ? blog.blog_title_draft : blog.blog_title}
                    </h4>
                    <Typography
                      className="card-text my-4"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                      }}
                      variant="body1">
                      {blog.blog_summary_draft ? blog.blog_summary_draft : blog.blog_summary}
                    </Typography>
                    <div className="back_border ps-3 py-1">
                      {/* <a target="_blank" rel="noreferrer" href={blog.url} style={{  textDecoration: 'underline' }} >Read more</a> */}
                      {blog.statichtml == true ?
                        (<a href={`/blog/${blog.url}.htm`} target="_blank">Read More</a>)
                        : (<a href={`/PreviewBlogs1/${blog._id}?campId=${campId}`} target="_blank">Read More</a>)
                      }
                      {/* <a href={`/PreviewBlogs1/${blog._id}`} target="_blank"  style={{ color: 'white' }} >Read More</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/*  */}
      {/* {!showAllBlogs && ( */}
      <div className="text-center my-5">
        <button type="button" className="btn btn-outline-primary px-5">  <a target="_blank" href={`/blogs?userid=${blogs && blogs[0]?.userid}`} style={{ textDecoration: 'underline', color: '#FFF' }}> Read more blogs</a>
        </button>
      </div>
      {/* )} */}
      {/* <div className="py-5 mx-sm-0 mx-2 backgroundbg"
      >
        <div className="container">
          <div className="row px-sm-0 px-2">
            <div className="col-lg-3 text-white text-lg-start text-center">
              <div>
                <div>
                  <img src={img54} alt="" />
                </div>
                <p className="fw_samibold">
                  OceSha/Code Genie Los Angeles, Ca 310-748-8901
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
              <div>
                <h6 className="f_heading mb-4">
                  Get news in your mailbox right away!
                </h6>
                <p className="fw_samibold">

                  In addition, stay in loop for our exclusive launches and limited
                  time deals.
                </p>
              </div>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Search" />
                <button className="btn btn-primary" type="submit">
                  Subscribe
                </button>
              </div>
            </div>

            <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
              <div>
                <div className="text-white">
                  <h6 className="fw_samibold">Products</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a href="https://ai-course.code-genie.ai/courses/ArtificialIntelligence">AI Course</a>
                    </li>
                    <li>
                      <a href="https://consulting.code-genie.ai/">AI Consulting</a>
                    </li>
                    <li>
                      <a href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                    </li>
                    <li>
                      <a href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
              <div>
                <div className="text-white">

                  <h6 className="fw_samibold">Support</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a href="/terms">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="/privacy">Privacy &amp; Cookie policy</a>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
            <div className="col-lg-3 text-white d-lg-block d-none">

              <div>
                <h5 className="mb-4 f_heading">
                  Get news in your mailbox right away!
                </h5>
                <p className="fw_samibold">

                  In addition, stay in loop for our exclusive launches and limited
                  time deals.
                </p>
              </div>

              News Subscribe
              <div className="input-group mb-3" onSubmit={handleSubmit(onSubmitHandler)}>
                <input className={`form-control ${errors["email"] ? "error" : ""}`}
                  {...register("email")}
                  type="email"
                  placeholder="Enter your mail"
                  onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                  Subscribe
                </button>
                {errors["email"] && (
                  <span className="error-message">{errors["email"]?.message}</span>
                )}
              </div>
              //
            </div>
          </div>
        </div>
      </div> */}
      {isMobile ? <Divider /> : ''}
      {/* Footer section */}
      <div className="py-5 mx-sm-0 mx-2">
        <div className="container">
          <div className="row px-sm-0 px-2">
            <div className="col-sm-12 col-lg-3 text-white text-lg-start text-center">
              <div>
                <div>
                  <Typography style={{ color: '#fff', fontWeight: 'bold', fontSize: 22 }}><img src={logo_white} alt="" />CodeGenie</Typography>

                </div>
                <div style={{ marginLeft: '14%' }}>
                  {planDetails?.twitterurl ? <Twitter onClick={() => window.open(planDetails?.twitterurl, '_blank')} /> : ''}
                  {planDetails?.fburl ? <Facebook onClick={() => window.open(planDetails?.fburl, '_blank')} /> : ''}
                  {planDetails?.pintresturl ? <Pinterest onClick={() => window.open(planDetails?.pintresturl, '_blank')} /> : ''}
                  {planDetails?.linkedinurl ? <LinkedIn onClick={() => window.open(planDetails?.linkedinurl, '_blank')} /> : ''}
                </div>
                <p /* className="fw_samibold" */ style={{ fontSize: 14, fontWeight: 'lighter' }}>
                  {/* Mr John Smith. 132, My Street, Kingston, New York 12401. */}
                </p>
              </div>
            </div>

            {planDetails?.newsletter === 1 ?
              <div className="col-lg-4 col-sm-12">
                <Typography sx={{ fontWeight: 'bold' }}>Newsletter</Typography>
                <Typography>Signup for exclusive offers, original stories,<br /> events and more.</Typography>
                <div>
                  <TextField className=""
                    placeholder="Enter your email"
                    sx={{
                      backgroundColor: 'transparent',
                      width: '100%',
                      height: '40px',
                      border: formErrors?.subscribeEmail ? '1px solid red' : '',
                      '.MuiOutlinedInput-root': {
                        height: '100%',
                        border: 'none',
                        borderRadius: '5px',
                      },
                      borderRadius: '5px',
                      '&:hover': {
                        border: 'none'
                      }

                    }} name="subscribeEmail" value={newsLetterEmail} onChange={(event) => setNewsLetterEmail(event.target.value)} />
                  {newsLetterEmail ? <span style={{ color: 'red' }}>{!validateEmail(newsLetterEmail) ? 'Invalid Email' : ''}</span> : ''}
                </div>
                <Button variant="contained" sx={{ color: '#fff', marginTop: '4%', width: '40%' }} onClick={handleSubscribeClick}>{subscriptionLoader ? <CircularProgress size={22} style={{ color: '#fff' }} /> : 'Subscribe'}</Button>
              </div> :
              <div className="col-lg-4 col-sm-12">
                &nbsp;
              </div>}

            <div className="col-lg-5 col-sm-12  text-lg-start text-sm-end" style={{ display: 'flex', justifyContent: 'right' }}>
              Copyright © 2024 Code-Genie.ai | All Rights Reserved.
            </div>

          </div>
        </div>
      </div>
      {/* footer section end*/}

    </>
  );
};

export default ADiveintoBlogs1;